<template>
  <v-card v-if="article">
    <v-img v-if="article.imageUrl"
      :src="article.imageUrl"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" 
      height="300px"
    >
      <v-card-title class="display-2" v-text="article.title"></v-card-title>
    </v-img>
    <v-card-title v-else class="display-2" v-text="article.title"></v-card-title>

    <div v-html="article.content" class="markdown mx-4 mt-8 pb-4 dynamic-content"> </div>
  </v-card>
</template>

<script>

import articleService from "@/services/articleService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Article",
  components: {
  },
  props: {
  },
  data: function() {
    return {
      article: null,
      title: null,
      image: null,
    };
  },
  created() {
  },
  async mounted() {
    this.article = await articleService.get(this.$route.params.id);

    if (this.article == null) {
      // todo: redirect to 404
    }
    this.$nextTick(() => {
      // when content is loaded
    });
    this.image = this.article.images && this.article.images.length > 0 ? this.article.images[0] : null;
    EventBus.$emit('page-header-change', { title: this.article.title, image: null/*this.article.images[0]*/} );

  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>